import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { navigate, graphql } from 'gatsby'
import Layout from '../layouts'
import Content, { HTMLContent } from '../components/Content'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Bg from './category08-bg'
import Heading from '../components/common/ProductDetailsHeading'
import DetailsWrapper from '../components/common/ProductDetailsWrapper'
import Details from '../components/common/ProductDetailsDescription'
import AddToCartDetailBtn from '../components/common/AddToCartDetailBtn'
// import DarkModeToggle from '../components/DarkModeToggle'

import { CartItemsContext } from '../context/CartItemsContext'

export const Category08PostTemplate = ({
  content,
  contentComponent,
  image,
  title,
  description,
  price,
  amount,
  allergens,
  incredients,
  origin,
  activ,
  helmet
}) => {
  const PostContent = contentComponent || Content

  // const [cart, setCart] = useState([])
  const [cart, setCart] = useContext(CartItemsContext)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if(localStorage.getItem("click&collect-cart") !== null) {
        let localCart = localStorage.getItem("click&collect-cart");
        setCart(JSON.parse(localCart))
      }else{
        localStorage.setItem("click&collect-cart", JSON.stringify(cart) )
      }
    }
  }, [])


    // const addToCart = (e, name) => {
    //   alert(name)
    //   localStorage.setItem("click&collect-cart", name)
    // }


    const addToCart = (e, name, price) => {
      let cartCopy = [...cart]
      //create a copy of our cart state, avoid overwritting existing state
      if(Array.isArray(cart) && cart.length) {
        let cartCopy = [];
      }
      else{
        cartCopy = [...cart]
      }

      //assuming we have an ID field in our item
      // name = item;
      const item = {
        name: name,
        price: price
      }

      //look for item in cart array
      //let existingItem = cartCopy.find(cartItem => cartItem.name == name);

      //if item already exists
      // if (existingItem) {
      //     existingItem.quantity += item.quantity //update item
      // } else { //if item doesn't exist, simply add it
      //   cartCopy.push(item)
      // }


      cartCopy.push(item)
      // cartCopy = [...cart, item]

      //update app state
      setCart(cartCopy)

      //make cart a string and store in local space
      let stringCart = JSON.stringify(cartCopy);
      localStorage.setItem("click&collect-cart", stringCart)
    }

  const productImage = getImage(image)

  return (
    <>
      <Bg />
      <section className="section">
        {helmet || ''}
        <div className='' style={{ marginTop: '2rem', marginBottom: '5rem' }}>
          <div className="content">
            <DetailsWrapper>

              <div className='columns is-multiline is-centered'>
                {/*<div className='column is-12 has-text-right'>
                  <DarkModeToggle />
                </div>*/}

                <div className='column is-12'>
                  <Heading className="title is-2 is-size-4-mobile is-size-3-tablet has-text-weight-bold is-bold-light has-text-centered">
                    {title}
                  </Heading>
                </div>

                {image ?
                  <div className='column is-6'>
                    <div style={{ margin: '0 auto', maxHeight: '250px', maxWidth: '250px', textAlign: 'center' }}>
                      {/*<Img objectFit="cover" objectPosition="10% 10%" fixed={image.childImageSharp.fixed} />*/}
                      <GatsbyImage
                        image={productImage}
                        alt='Produktbild'
                        // height={200}
                        // layout='constrained'
                        imgStyle={{ margin: '0 auto', maxWidth: 'auto', maxHeight: '250px' }}
                        // objectPosition='50% 50%'
                        loading='eager'
                      />
                    </div>
                  </div>
                  : null}

                <div className="column is-6" style={{ margin: 'auto auto' }}>

                  <Details>
                    {description ?
                      <>
                        <p>{description}</p>
                      </>
                      : null}

                    {amount ?
                      <>
                        <span className='subtitle is-5 has-text-primary'>Menge:</span><span>{' '}{amount}</span>
                        <br />
                      </> : null}
                    {incredients ?
                      <>
                        <span className='subtitle is-5 has-text-primary'>Zutaten:</span><span>{' '}{incredients}</span>
                        <br />
                        <br />
                      </> : null}
                    <PostContent content={content} />
                    {allergens ?
                      <>
                        <br />
                        <span className='subtitle is-5 has-text-primary'>Allergene:</span><span>{' '}{allergens}</span>
                      </> : null}
                    <br />
                    {origin ?
                      <>
                        <span className='subtitle is-5 has-text-primary'>Herkunft:</span><span>{' '}{origin}</span>
                      </> : null}
                    <p className="subtitle is-4 has-text-right has-text-weight-bold has-text-primary" style={{ marginTop: '20px' }}>€{' '}{price}.-</p>
                  </Details>
                  <AddToCartDetailBtn className='button is-normal is-primary' onClick={(e) => addToCart(e, `${title}`, `${price}`)} style={{ margin: '1rem auto' }}>
                    zur Bestellung hinzufügen <span className='addIcon'>+</span>
                  </AddToCartDetailBtn>
                </div>
              </div>
            </DetailsWrapper>
            <div className='column is-12 has-text-centered' style={{ marginTop: '2rem' }}>
              {/* <Link className='button is-primary' to='/karte/heiss'>zurück
              </Link> */}
              <button
                className='button is-primary'
                onClick={() => { navigate(-1) }}
              >
                zurück
              </button>

            </div>

          </div>
        </div>
      </section>
    </>
  )
}

Category08PostTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.string,
  amount: PropTypes.string,
  allergens: PropTypes.string,
  origin: PropTypes.string,
  incredients: PropTypes.string,
  helmet: PropTypes.object,
}

const Category08Post = ({ data }) => {
  const { markdownRemark: post, navbarData, footerData, bannerData } = data

  return (
    <Layout navbarData={navbarData} footerData={footerData} bannerData={bannerData}>
    {/*<Layout navbarData={navbarData} bannerData={bannerData} footerData={footerData}>*/}
      <Category08PostTemplate
        image={post.frontmatter.image}
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Snacks">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        price={post.frontmatter.price}
        amount={post.frontmatter.amount}
        incredients={post.frontmatter.incredients}
        origin={post.frontmatter.origin}
        allergens={post.frontmatter.allergens}
        title={post.frontmatter.title}
        active={post.frontmatter.active}
      />
    </Layout>
  )
}

Category08Post.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Category08Post

export const pageQuery = graphql`
  query Category08PostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        image {
          childImageSharp {
           gatsbyImageData(
             height: 250
             placeholder: BLURRED
             formats: [AUTO, WEBP, AVIF]
           )
         }
        }
        date(formatString: "MMMM DD, YYYY")
        title
        description
        price
        amount
        allergens
        origin
        incredients
        active
      }
    }
    ...LayoutFragment
  }
`
